import { render, staticRenderFns } from "./ExamReview.vue?vue&type=template&id=463cfc2e&scoped=true&"
import script from "./ExamReview.vue?vue&type=script&lang=js&"
export * from "./ExamReview.vue?vue&type=script&lang=js&"
import style0 from "./ExamReview.vue?vue&type=style&index=0&id=463cfc2e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "463cfc2e",
  null
  
)

export default component.exports