<template>
  <section class="container pb-8">
    <div class="exam">
      <div
        v-if="questionsData.length || courseType === 2"
        class="exam__content"
      >
        <div id="examMain" class="exam-main">
          <div class="exam-main__title">
            測驗日期：{{ data.examinationStartDate | toTaipei | moment('YYYY/MM/DD HH:mm:ss') }}
          </div>
          <div class="exam-main__content">
            <div class="exam-question">
              <div class="exam-question__tool">
                <span>{{ currentTopic.questionDifficultyName }}</span>
                <span>{{ currentTopic.questionTypeName }}</span>
                <a @click="handleOpenFeedBack()">
                  <i class="fa fa-bug" />
                </a>
              </div>

              <div class="exam-question__title">
                <p>{{ currentTopic.questionName }}</p>
              </div>

              <div class="exam-question__knowledge">
                <template v-for="item in currentTopic.knowledges">
                  <p :key="item.knowledgeId">{{ item.knowledgeName }}</p>
                </template>
              </div>

              <div class="exam-question__list">
                <template v-for="(item, index) in currentTopic.answers">
                  <el-checkbox
                    v-if="item.answerName !== ''"
                    v-model="item.isAnswer"
                    :key="item.answerId"
                    :true-label="1"
                    :false-label="0"
                    :disabled="true"
                    class="exam-question__option"
                    :class="{
                      'exam-question__option--error': isErrorAnswer(item),
                      'exam-question__option--true': isTrueAnswer(item) || isCorrectAnswer(item),
                    }"
                  >
                    <span v-if="isTrueAnswer(item)">(正解)</span>
                    <i
                      v-if="isCorrectAnswer(item)"
                      class="fa fa-check-circle"
                      :style="{ color: '#62df68 ' }"
                    />
                    <i
                      v-if="isErrorAnswer(item)"
                      class="fa fa-times-circle"
                      :style="{ color: '#ff6363' }"
                    />

                    {{ getTitle(index) }} {{ item.answerName }}
                  </el-checkbox>
                </template>
              </div>

              <div class="exam-explain">
                <div
                  class="exam-explain__head"
                  @click="explainShow = !explainShow"
                >
                  <div>
                    <i
                      class="el-alert__icon"
                      :class="{
                        'el-icon-info': !currentTopic.questionsNote,
                        'el-icon-circle-plus': currentTopic.questionsNote && !explainShow,
                        'el-icon-remove': currentTopic.questionsNote && explainShow,
                      }"
                    />

                    <span>
                      {{ currentTopic.questionsNote ? '查看詳解' : '暫無詳解' }}
                    </span>

                    <font v-if="currentTopic.questionAuthor">
                      - {{ currentTopic.questionAuthor }}
                    </font>
                  </div>

                  <span v-if="getCorrectOfTotal">
                    {{ getCorrectOfTotal }}
                  </span>
                </div>

                <div
                  v-if="explainShow && currentTopic.questionsNote"
                  class="exam-explain__content"
                  v-html="currentTopic.questionsNote"
                />
              </div>

              <div class="exam-question__buttonBox">
                <a
                  v-if="questionIndex !== 0"
                  class="exam-question__prev btn btn-text"
                  @click="handlePrevTopic"
                >
                  <span>上一題</span>
                </a>

                <a
                  v-if="data.questions && questionIndex < data.questions.length - 1"
                  class="exam-question__next btn btn-text"
                  @click="handleNextTopic()"
                >
                  <span>下一題</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="exam-list">
          <div class="exam-list__title">題目列表</div>
          <div class="exam-list__tabs">
            <a
              class="btn btn-sm w-100 btn-primary"
              :class="{ 'btn-light': courseType !== 1 }"
              @click="courseType = 1"
            >
              <i class="fa fa-book mr-2" aria-hidden="true" />
              <span>題目列表</span>
            </a>
            <a
              class="btn btn-sm w-100 btn-primary"
              :class="{ 'btn-light': courseType !== 2 }"
              @click="courseType = 2"
            >
              <i class="fa fa-paperclip mr-2" aria-hidden="true" />
              <span>錯題本</span>
            </a>
          </div>

          <div
            v-if="questionsData.length"
            class="exam-list__summary"
          >
            <i class="fa fa-th" aria-hidden="true" />
            <span>{{ summaryTime }}</span>
            <span>{{ summaryRate }}</span>
          </div>

          <div class="exam-list__content">
            <template v-if="questionsData.length">
              <div
                v-for="(item, index) in questionsData"
                :key="`${index}-${item.questionCode}`"
                class="exam-list__item"
                :class="{'exam-list__item--active': item.seq - 1 === questionIndex}"
              >
                <a
                  class="exam-list__item-text"
                  @click="handleShowTopic(item, item.seq - 1)"
                >
                  <i
                    v-if="item.isTrueAnswer === false"
                    class="fa fa-times-circle"
                    style="color: red;"
                  />

                  <i
                    v-else
                    class="fa fa-check-circle"
                    :style="{ color: item.isTrueAnswer ? '#cccf2d' : '#c0c4cc' }"
                  />

                  <span>第 {{ item.seq }} 題</span>
                </a>
              </div>
            </template>

            <p v-else>暫無資料</p>
          </div>
        </div>
      </div>

      <div
        v-else
        class="exam__empty"
      >
        <i class="fa fa-times-circle"/> 無作答紀錄
      </div>

      <div class="exam__button">
        <router-link
          class="btn btn-info"
          :to="`/practices/examHistory/${data.subjectId}`"
        >
          返回列表
        </router-link>
      </div>
    </div>

    <FeedBack
      :show.sync="feedBackShow"
      :subject-name="subjectName"
      :question-code="questionCode"
    />
  </section>
</template>

<script>
import { GetPracticeSbujectBreadcrumb } from '@/api/BuyPracticeApi';
import { GetExaminationById } from '@/api/MemberExamination';

import FeedBack from '@/components/Practice/FeedBack.vue';

/* global $ */
export default {
  props: ['currentPage'],
  components: {
    FeedBack,
  },
  data() {
    return {
      courseType: 1,
      subjectName: '',
      questionCode: '',
      feedBackShow: false,
      showTopic: false,
      data: {},
      currentTopic: {
        isFavorite: false,
        isMarkAnswer: false,
        isTrueAnswer: false,
        questionCode: '',
        questionId: 0,
        questionName: '',
        questionType: 0,
        questionTypeName: '',
        questionsNote: '',
        recordNoteCount: 0,
        answers: [],
      },

      questionIndex: 0,
      explainShow: false,
    };
  },
  created() {
    this.GetExaminationById();
    this.GetBreadcrumb();
  },
  computed: {
    summaryTime() {
      return `測驗時間：${this.data.questionTime}分鐘`;
    },
    summaryRate() {
      return `錯誤率：${this.data.questionIncorrect}/${this.data.questionTotal}`;
    },
    questionsData() {
      const tmpQuestions = this._.filter(this.data.questions,
        (item) => this.courseType === 1
          || (this.courseType === 2 && item.isTrueAnswer === false));

      return tmpQuestions;
    },
    getCorrectOfTotal() {
      const { responderCorrect, responderTotal } = this.currentTopic;

      if (responderCorrect && responderTotal) {
        return `考古題成績答對率：${responderCorrect}/${responderTotal} (人)`;
      }

      return '';
    },
  },
  methods: {
    GetBreadcrumb() {
      const postData = {
        Id: this.$route.params?.id,
      };

      GetPracticeSbujectBreadcrumb(postData)
        .then((response) => {
          if (response.data.statusCode === 0) {
            const { data } = response.data;

            if (!this._.isEmpty(data)) {
              this.$emit('update:currentPage', [
                {
                  name: 'practicesCategory',
                  title: `${data.subJectName}`,
                  path: `/practices/${data.subjectId}`,
                },
                {
                  name: 'exam',
                  title: '考試去',
                  path: `/practices/exam/${data.subjectId}`,
                },
                {
                  name: 'examHistory',
                  title: '試卷紀錄',
                  path: `/practices/examHistory/${data.subjectId}`,
                },
                {
                  name: 'ExamReview',
                  title: '測驗',
                  path: '',
                },
              ]);
            }
          }
        });
    },
    // 取得所有題目
    GetExaminationById() {
      const id = this.$route.params?.examId;

      GetExaminationById(id)
        .then((response) => {
          if (response.data.statusCode === 0) {
            this.data = this._.cloneDeep(response.data.data);

            this._.forEach(this.data.questions, (item) => {
              this._.sortBy(item.answers, ['answerId']);
            });

            this.handleShowTopic(this.data.questions[0], 0);
          }
        })
        .catch(() => {
          this.$router.replace('/error').catch();
        });
    },
    // 顯示選擇的題目在畫面上
    handleShowTopic(topic, index) {
      this.showTopic = true;
      // 清空作答
      this.currentTopic = this._.cloneDeep(topic);
      this.questionIndex = index;
      this.explainShow = false;

      this.$nextTick(() => {
        $('.exam-list__content').animate({
          scrollTop: 48 * this.questionIndex,
        }, 0);

        if ($(window).width() < 992) {
          $('html, body').animate({
            scrollTop: $('#examMain').offset().top - 62,
          }, 0);
        }
      });
    },
    handlePrevTopic() {
      const index = this.data.questions[this.questionIndex - 1].seq - 1;
      const topic = this._.cloneDeep(this.data.questions[this.questionIndex - 1]);

      this.handleShowTopic(topic, index);
    },
    handleNextTopic() {
      const index = this.data.questions[this.questionIndex + 1].seq - 1;
      const topic = this._.cloneDeep(this.data.questions[this.questionIndex + 1]);

      this.handleShowTopic(topic, index);
    },
    isErrorAnswer(item) {
      if (this.currentTopic.responderAnswers.length === 0) {
        return false;
      }

      return item.isAnswer !== 1
        && this._.includes(this.currentTopic.responderAnswers, item.answerId);
    },
    isTrueAnswer(item) {
      if (this.currentTopic.responderAnswers.length === 0) {
        return false;
      }

      return item.isAnswer === 1
        && !this._.includes(this.currentTopic.responderAnswers, item.answerId);
    },
    isCorrectAnswer(item) {
      if (this.currentTopic.responderAnswers.length === 0) {
        return false;
      }

      return item.isAnswer === 1
        && this._.includes(this.currentTopic.responderAnswers, item.answerId);
    },
    handleOpenFeedBack() {
      this.questionCode = this.currentTopic.questionCode;
      this.feedBackShow = true;
    },
    getTitle(index) {
      const title = ['(A)', '(B)', '(C)', '(D)'];

      return title[index];
    },
  },
};
</script>

<style lang="scss" scoped>
// none
</style>
