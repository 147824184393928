import baseAxios from '@/api/base';

// 考試去，出考題
export const Examination = (data) => baseAxios.post('/FrontEnd/MemberExamination/Examination', data);

// 考試去，送出考卷
export const SaveExamination = (data) => baseAxios.post('/FrontEnd/MemberExamination/SaveExamination', data);

/**
 * 考試去，試卷紀錄明細
*  @param {object} data {
  "pageIndex": 1,
  "pageSize": 10,
  "data": [
    "subjectId": 1
  ]
}
 */
export const GetExaminations = (data) => baseAxios.post('/FrontEnd/MemberExamination/GetExaminations', data);

// 考試去，試卷紀錄明細
export const GetExaminationById = (ExaminationId) => baseAxios.post('/FrontEnd/MemberExamination/GetExaminationById', { ExaminationId });
